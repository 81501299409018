<template>
    <div>
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>
                {{ "微信群阅读详情" }}
                <span class="eye" @click.stop="changeEye">
                    <Icon :type="!hasSensitive ? 'md-eye-off' : 'md-eye'" />
                </span>
            </template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search"></Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <!-- 分页table -->
                <LiefengTable
                    :talbeColumns="pageForm.talbeColumns"
                    :tableData="pageForm.tableData"
                    :loading="pageForm.loading"
                    :fixTable="true"
                    :curPage="pageForm.page"
                    :total="pageForm.total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageForm.pageSize"
                    @hadlePageSize="changeTableSize"
                ></LiefengTable>
            </template>
            <!-- 新增modal -->
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            //分页参数
            pageForm: {
                talbeColumns: [
                    {
                        title: "微信群名称",
                        key: "groupName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "姓名",
                        key: "userName",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "手机号",
                        key: "mobile",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "阅读时间",
                        key: "gmtCreate",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                        },
                    },
                ],
                tableData: [],
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0,
                hasSensitive: true,
            },
        }
    },
    //create函数,刚加载页面时调用
    async created() {
        this.selectPage()
    },
    methods: {
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.selectPage()
        },
        /*----- 分页事件部分 -------*/

        //分页事件改变
        changeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.pageSize = val.pageSize
            this.selectPage()
        },
        // 方法部分
        /*----- 接口部分 -------*/
        async selectPage() {
            this.pageForm.loading = true
            await this.$get("/info/api/pc/information/v2/selectWexinLogViewPage", {
                informationId:this.$route.query.id,
                page: this.pageForm.page,
                pageSize: this.pageForm.pageSize,
                hasSensitive: !this.hasSensitive,
            }).then(res => {
                this.pageForm.tableData = []
                this.pageForm.loading = false
                if (res.code == 200) {
                    this.pageForm.tableData = res.dataList
                    this.pageForm.total = res.maxCount
                    this.pageForm.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
</style>